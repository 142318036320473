<template>
  <div>
    <div class="page-header page-header-only">
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Getting in touch</h2>
        <p class="description">We'd love to hear from you.</p>
        <div class="row text-left">
          <div class="col-lg-6 col-12">
            <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020.270152088749!2d115.85871442435887!3d-31.871240829044613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b02cae3266af%3A0xc9e7811a0ba95b04!2sAgeless%20Beauty%20Skin%20Care%20Clinic!5e0!3m2!1sen!2sau!4v1616069776230!5m2!1sen!2sau" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020.270152088749!2d115.85871442435887!3d-31.871240829044613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b02cae3266af%3A0xc9e7811a0ba95b04!2sAgeless%20Beauty%20Skin%20Care%20Clinic!5e0!3m2!1sen!2sau!4v1616069776230!5m2!1sen!2sau" width="100%" height="500" min-height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            <p />
          </div>
          <div class="col-lg-6 col-md-10 col-12 ml-auto mr-auto description">
            <div class="row">
              <div class="col-3">
                <p>
                  Address<br />
                  <br />
                </p><p>
                  Phone
                </p><p>
                  Email<br />
                  <br />
                </p><p>
                  Opening hours
                </p>
              </div>
              <div class="col-9">
                <p>
                  Suite 2, 37 Yirrigan Drive<br />
                  Mirrabooka WA 6061<br />
                </p><p>
                  0438 908 550<br />
                </p><p>
                  skincare@agelessbeauty.com.au<br />
                  bookings@agelessbeauty.com.au
                </p>
                <div class="row">
                  <div class="col-3">
                    <p>
                      Mon<br />
                      Tues<br />
                      Wed<br />
                      Thur<br />
                      Fri<br />
                      Sat<br />
                      Sun
                    </p>
                  </div>
                  <div class="col">
                    <p>
                      Closed<br />
                      11:00 am – 7:00 pm<br />
                      9:00 am – 5:30 pm<br />
                      11:00 am – 7:00 pm<br />
                      9:00 am – 5:30 pm<br />
                      9:00 am – 2:00 pm<br />
                      Closed
                    </p>
                  </div>
                </div>  
                <p>
                  Other hours by arrangement
                </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Send us a message</h2>
        <p class="description">We'd love to hear from you!</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <form
              name="web-contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              @submit.prevent="handleSubmit"
            >
              <fg-input
                class="input-lg"
                placeholder="Name..."
                v-model="form.name"
                addon-left-icon="now-ui-icons users_circle-08"
              >
              </fg-input>
              <fg-input
                class="input-lg"
                placeholder="Email..."
                v-model="form.email"
                addon-left-icon="now-ui-icons ui-1_email-85"
              >
              </fg-input>
              <div class="textarea-container">
                <textarea
                  class="form-control"
                  name="name"
                  rows="4"
                  cols="80"
                  v-model="form.message"
                  placeholder="Type a message..."
                ></textarea>
              </div>
              <div class="send-button">
                <n-button type="info" round block size="lg" @click="handleSubmit">Send Message</n-button>
              </div>
              {{messageText}}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Button, FormGroupInput } from '@/components';
import axios from 'axios'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      messageText: "",
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    resetForm() {
      this.$set(this.form, 'name', '');
      this.$set(this.form, 'email', '');
      this.$set(this.form, 'message', '');
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    },
    handleSubmit() {
      
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' },
      };

      axios.post(
        '/',
        this.encode({
          'form-name': 'web-contact',
          ...this.form,
        }),
        axiosConfig,
      ).then( (response) => {
        this.resetForm();
        this.messageText = "Message sent.  Thank you!";
      })
      .catch( (error) => {
        //console.log( "Error submitting form: " + error.message )
        this.messageText = "Message send failed!";
      })      
    }
  }
};
</script>
<style>
@media (orientation:landscape) {
    .hide-on-landscape {
        display: none;
    }
}

@media (orientation:portrait) {
    .hide-on-portrait {
        display: none;
    }
}
</style>
