<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('agelessImg/banner1.jpg')"
      >
      </parallax>
      <div class="content-center" style="margin-top: 1em;">
        <div class="container">
          <h2 style="margin-bottom: 0.25em;">Ageless Beauty</h2>
          <div class="text-center">
              <span style="margin-right: 1em;">Skin Care</span>
              <span style="margin-right: 1em;">Beauty Therapy</span>
              <span>Massage</span>
          </div>
          <img style="margin-top: 2.5em; height: 20vh;" src="agelessImg/logo.png" />
        </div>
      </div>
    </div>

    <div class="section section-services">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What we do</h2>
          </div>
        </div>
        <div class="separator separator-info"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-2" />
            <div class="col-md-8 description">
              <h3>
                All Your Needs
              </h3>
              <!--
              <p>
                Ageless Beauty Skin Care Clinic specialise in the treatment cellulite using 
                Acoustic Wave Therapy and Rejuvenating skin treatments using Environ and 
                Itraceuticals products. Our clinic also offers a full range of beauty treatments.
              </p>
              -->
              <p>
                Ageless Beauty Skin Care Clinic is Perth’s principal Cellulite Clinic offering specialised treatments, inlcuding:
              </p>
              <p>
                <ul>
                  <li>STORZ Medical Acoustic Wave Therapy</li>
                  <li>Medical-grade Mattioli MicroDermabrasion</li>
                  <li>'Iso-Lift' DermoElectroporation</li>
                  <li>Photo Rejuvenation and Environ Skincare</li>
                  <li>Intraceuticals O2 facial Infusions, and</li>
                  <li>A range of high quality therapeutic treatments.</li>
                </ul>
              </p>
              <p>
                Ageless Beauty Skin Care Clinic also offers a full range of beauty treatments, including:
              </p>
              <p>
                <ul>
                  <li>
                    Massage
                  </li><li>
                    Facials
                  </li><li>
                    Waxing
                  </li><li>
                    Eyelash / eyebrow tinting
                  </li><li>
                    Manicures and pedicures
                  </li>
                </ul>
                <!-- to cellulite and skin-tightening treatments, 
                there is something to cater for your every want or need -->
              </p>
              <p>
                Take time out to be indulged and 
                pampered and allow yourself to escape all earthly pressures of modern day living with 
                one of our spa packages. At Ageless Beauty, we are confident that you will enjoy your 
                visit and look forward to welcoming you.
              </p>
              <p>
                A full list of services and prices can be found <a href="https://www.facebook.com/AgelessBeautySkinCareClinic">on our Facebook page</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Button, FormGroupInput } from '@/components';
import axios from 'axios'

export default {
  name: 'services',
  bodyClass: 'services-page',
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
